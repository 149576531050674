<template>
  <Head>
    <Title>404 - Page not found</Title>
  </Head>
  <div class="error-page d-flex align-items-center justify-content-center">
    <div class="text-center">
      <h1 class="error-code">404</h1>
      <p class="error-message">Oops! Page not found.</p>
      <NuxtLink to="/">Go back to Home</NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error-page {
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);

  .error-code {
    font-size: 6rem;
    font-weight: bold;
    color: #1f299c;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .error-message {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .btn-primary {
    background-color: #1f299c;
    border-color: #1f299c;
    padding: 0.75rem 1.5rem;
    font-size: 1.1rem;
    transition: all 0.3s ease;

    &:hover {
      background-color: darken(#1f299c, 10%);
      border-color: darken(#1f299c, 10%);
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
